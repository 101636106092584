import React, { useEffect } from "react";
import ProgramsProd from "./TodayProgMachines/ODPs";
import { useAppDispatch } from "../../_App/Redux/hooks";
import LandingMachines from "../Machines/LandingMachines";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {}, [dispatch]);
  return (
    <div>
      <div className="row">
        <div className="col-12 layout-spacing">
          <div
            className="widget widget-one"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="widget-heading">
              <h6 className="">Vos machines</h6>
            </div>

            <LandingMachines />
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div className="row">
        <div className="col-12 layout-spacing">
          <div
            className="widget widget-one"
            style={{ backgroundColor: "transparent" }}
          >
            <div
              className="widget-heading"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h6 className="mb-0">Programme de production d'aujourd'hui</h6>
              <button
                className="btn btn-primary mb-2 mr-2"
                onClick={() => (window.location.href = "/calendar")}
              >
                <i className="far fa-calendar-alt mr-2"></i>Planning
              </button>
            </div>
            <div
              className="row mb-2"
              style={{
                background: "#fff",
                textAlign: "center",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <div className="col-6">ODF</div>
              <div className="col-6">Machine</div>
            </div>
            <ProgramsProd />
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Dashboard;
