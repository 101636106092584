import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComponent = ({ data }: any) => {
  // Prepare data for the pie chart
  const chartData = {
    labels: data?.map((machine: any) => machine.machine_code),
    datasets: [
      {
        label: "Nombre de pannes",
        data: data?.map((machine: any) => parseFloat(machine.times_down) || 0),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF5733",
          "#4CAF50",
          "#FFB300",
          "#008CBA",
          "#F44336",
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div>
      <h2>Machines en pannes</h2>
      <Pie data={chartData} />
    </div>
  );
};

export default PieChartComponent;
